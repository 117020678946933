import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import { DateOptions } from "../utils/dateFormat";
import ReactMarkdown from "react-markdown";
import { LegalDataQuery } from "../../graphqlTypes";
import { DeepNonNullable } from "ts-essentials";

export const query = graphql`
  query LegalData($slug: String!) {
    strapiLegal(slug: { eq: $slug }) {
      id
      slug
      title
      category
      numberSections
      updated_at
      sections {
        id
        title
        numberSection
        section {
          id
          text
        }
      }
    }
  }
`;

const LegalPage = ({ data }: { data: DeepNonNullable<LegalDataQuery> }) => {
  const { updated_at, category, title, sections, numberSections } =
    data.strapiLegal;

  return (
    <Layout>
      <Seo title={title} />
      <div className="flex flex-col mx-auto px-4 xl:px-0 mt-4 mb-4">
        <p className="text-xl text-gray-300">{category}</p>
        <p className="text-4xl text-gray-200 font-semibold mb-2">{title}</p>
        <p className="text-sm text-gray-300 font-light">
          Last updated on{" "}
          {new Date(updated_at).toLocaleString("en-US", DateOptions)}
        </p>
      </div>
      <div className="full-width-strip bg-accuRankerCream w-full py-12">
        <div className="max-w-7xl mx-auto px-4 xl:px-0 mt-4 mb-4">
          {sections.map((section, outerIndex) => (
            <div key={section.id}>
              <h2>
                {numberSections && outerIndex + 1 + ". "}
                {section.title}
              </h2>
              <ul className="ml-0">
                {section.section.map((line, innerIndex) => (
                  <li key={line.id}>
                    <ReactMarkdown>
                      {section.numberSection
                        ? "**" +
                          (outerIndex + 1) +
                          "." +
                          (innerIndex + 1) +
                          "** " +
                          line.text
                        : line.text}
                    </ReactMarkdown>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default LegalPage;
